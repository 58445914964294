import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var value = ref([]); // 值

    var options = ref([]); // 选项
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });

      if (props.answer) {
        value.value = Object.keys(props.answer).map(function (key) {
          return key;
        });
      }
    }

    init(); // 选择回调

    function changeValue(e) {
      if (props.config.max_select === "" || e.length <= props.config.max_select) {
        // 更新值
        value.value = e; // 更新答案

        if (e.length) {
          var answer = {};
          e.forEach(function (val) {
            answer[val] = 1;
          });
          context.emit("update:answer", answer);
        } else {
          context.emit("update:answer", null);
        }
      }
    }

    return {
      value: value,
      options: options,
      changeValue: changeValue
    };
  }
});