import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 错误
    error: {
      type: String,
      default: ""
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var password1 = ref("");
    var password2 = ref(""); // 初始化

    function init() {
      if (props.answer) {
        password1.value = props.answer.value;
        password2.value = props.answer.value;
      }
    }

    init(); // 更新答案

    function updateAnswer() {
      var error = "";
      var answer = null;

      if (password1.value) {
        if (password1.value.length < props.config.min) {
          error = "\u8BF7\u81F3\u5C11\u8F93\u5165".concat(props.config.min, "\u4F4D");
        } else if (props.config.text_type === 1 && !/([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*/.test(password1.value)) {
          error = "密码需要包含字母/数字/字母和数字";
        } else if (props.config.text_type === 2 && !/^\S*(?=\S{3,})(?=\S*\d)(?=\S*[A-Za-z])(?=\S*[!@#$%^&*? ])\S*$/.test(password1.value)) {
          error = "密码需要包含字母/数字/符号/字母和数字/字母和符号/符号和数字/字母、数字和符号";
        } else if (props.config.text_type === 3 && !/^\S*(?=\S{4,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/.test(password1.value)) {
          error = "密码需要包含大/小写字母/数字/符号/大/小写字母和数字/大/小写字母和符号/符号和数字/大/小写字母、数字和符号";
        } else if (props.config.text_type === 4 && !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{2,}$/.test(password1.value)) {
          error = "密码必须为字母和数字";
        } else if (password1.value !== password2.value) {
          error = "两次输入密码不一致";
        } else {
          answer = {
            value: password1.value
          };
        }
      }

      context.emit("update:error", error);
      context.emit("update:answer", answer);
    }

    return {
      password1: password1,
      password2: password2,
      updateAnswer: updateAnswer
    };
  }
});