import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.find.js";
import { computed, defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 选项
    var options = ref([]); // 计算总和

    var sum = computed(function () {
      var a = 0;
      options.value.forEach(function (option) {
        a += option.value * 1 || 0;
      });
      return a;
    }); // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });

      if (props.answer) {
        options.value.forEach(function (option) {
          option.value = props.answer[option.option_key];
        });
      }
    }

    init(); // 输入回调(更新答案)

    function updateAnswer(e, option) {
      var _value$toString$match;

      var value = e.target.value;
      option.value = (_value$toString$match = value.toString().match(/^\d+(?:\.\d{0,2})?/)) === null || _value$toString$match === void 0 ? void 0 : _value$toString$match[0];
      var answer = {};
      var index = options.value.findIndex(function (option) {
        answer[option.option_key] = option.value;
        return !option.value && option.value !== 0;
      });

      if (index === -1) {
        context.emit("update:answer", answer);
      } else {
        context.emit("update:answer", null);
      }
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        options: options.value
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      // 更新关联题选项
      var newOptions = [];
      props.list.forEach(function (list) {
        newOptions = [].concat(_toConsumableArray(newOptions), _toConsumableArray(list.options));
      });

      if (props.answer) {
        newOptions.forEach(function (newOption) {
          if (!newOption.value && newOption.value !== 0) {
            newOption.value = props.answer[newOption.option_key];
          }
        });
      } else {
        newOptions.forEach(function (newOption) {
          var oldOption = options.value.find(function (oldOption) {
            return oldOption.option_key === newOption.option_key;
          });
          if (!newOption.value) newOption.value = oldOption === null || oldOption === void 0 ? void 0 : oldOption.value;
        });
      }

      options.value = newOptions; // 更新答案
      // updateAnswer();
    }, {
      deep: true
    });
    return {
      options: options,
      sum: sum,
      updateAnswer: updateAnswer
    };
  }
});