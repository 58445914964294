import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2464439e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "reply-list-item"
};
var _hoisted_2 = {
  class: "comment-avatar"
};
var _hoisted_3 = {
  class: "comment-main"
};
var _hoisted_4 = {
  class: "nickname"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  class: "edit-comment"
};
var _hoisted_7 = {
  class: "date"
};
var _hoisted_8 = {
  class: "comment-btn"
};
var _hoisted_9 = {
  key: 0,
  class: "comment-editor",
  style: {
    "padding-left": "38px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_avatar = _resolveComponent("avatar");

  var _component_remark_editor = _resolveComponent("remark-editor");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_avatar, {
    nickname: _ctx.info.reply_nickname,
    avatar: _ctx.info.reply_avatar
  }, null, 8, ["nickname", "avatar"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.info.reply_nickname), 1), _createElementVNode("div", {
    class: "content",
    innerHTML: _ctx.info.content
  }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.info.created_at), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("i", {
    class: "iconfont icon-pinglun",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.OpenReplyEditor && _ctx.OpenReplyEditor.apply(_ctx, arguments);
    })
  }), _ctx.info.reply_id === _ctx.userId ? (_openBlock(), _createElementBlock("i", {
    key: 0,
    class: "iconfont icon-qingkong1",
    style: {
      "margin-left": "12px"
    },
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.deleteReply(_ctx.info.id);
    })
  })) : _createCommentVNode("", true)])])])]), _ctx.isRenderEditor ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_remark_editor, {
    content: _ctx.content,
    "onUpdate:content": [_cache[2] || (_cache[2] = function ($event) {
      return _ctx.content = $event;
    }), _cache[3] || (_cache[3] = function (newValue) {
      return _ctx.content = newValue;
    })],
    "onCommit:message": _ctx.sendReplyMessage,
    "onCancel:content": _ctx.handleCancel
  }, null, 8, ["content", "onCommit:message", "onCancel:content"])])) : _createCommentVNode("", true)], 64);
}