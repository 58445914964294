import "core-js/modules/es.array.slice.js";
import { defineComponent, computed } from "vue";
export default defineComponent({
  props: {
    nickname: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props) {
    var name = computed(function () {
      return props.nickname ? props.nickname.slice(-1) : '';
    });
    return {
      name: name
    };
  }
});