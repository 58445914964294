import Signature from "@/views/planetDesign/Design/components/Signature.vue";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
export default defineComponent({
  components: {
    Signature: Signature
  },
  props: {
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var url = ref(""); // 初始化

    function init() {
      if (props.answer) {
        url.value = props.answer.value;
      }
    }

    init(); // 完成并上传

    function save(imgUrl) {
      context.emit("update:answer", {
        value: imgUrl
      });
      message.success("上传成功");
    }

    return {
      url: url,
      save: save
    };
  }
});