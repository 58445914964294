import "core-js/modules/es.number.constructor.js";
import { defineComponent, inject } from "vue";
import RichText from "@/components/RichText.vue";
import Remark from "@/views/Answer/components/Remark";
export default defineComponent({
  components: {
    RichText: RichText,
    Remark: Remark
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    desc: {
      type: String,
      default: ""
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否答题模式
    isAnswer: {
      type: Boolean,
      default: false
    },
    // 显示标题
    showTitle: {
      type: [Boolean, Number],
      default: false
    },
    // 显示介绍语
    showDesc: {
      type: [Boolean, Number],
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  setup: function setup() {
    var themeColor = inject("themeColor"); // 主题颜色

    return {
      themeColor: themeColor
    };
  }
});