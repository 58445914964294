import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ff16193e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 0,
  class: "time"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_input = _resolveComponent("a-input");

  var _component_a_spin = _resolveComponent("a-spin");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createVNode(_component_a_input, {
    value: _ctx.phone,
    "onUpdate:value": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.phone = $event;
    }),
    placeholder: _ctx.config.placeholder,
    onChange: _ctx.updateAnswer,
    disabled: _ctx.disabled
  }, null, 8, ["value", "placeholder", "onChange", "disabled"])]), _ctx.config.is_message ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_a_input, {
    class: "code",
    value: _ctx.code,
    "onUpdate:value": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.code = $event;
    }),
    placeholder: "请输入验证码",
    onChange: _ctx.updateAnswer,
    disabled: _ctx.disabled
  }, {
    suffix: _withCtx(function () {
      return [_ctx.time ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.time), 1)) : (_openBlock(), _createBlock(_component_a_spin, {
        key: 1,
        spinning: _ctx.loading
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            class: "get-code",
            onClick: _cache[1] || (_cache[1] = function () {
              return _ctx.getCode && _ctx.getCode.apply(_ctx, arguments);
            })
          }, "获取验证码")];
        }),
        _: 1
      }, 8, ["spinning"]))];
    }),
    _: 1
  }, 8, ["value", "onChange", "disabled"])])) : _createCommentVNode("", true)]);
}