import "core-js/modules/es.array.includes.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_select_option = _resolveComponent("a-select-option");

  var _component_a_select = _resolveComponent("a-select");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isMobile ? '' : 'cascader')
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsLevel, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.isMobile ? 'mob-select' : 'select'),
      key: index
    }, [[3, 4, 5].includes(_ctx.config.quick_type) ? (_openBlock(), _createBlock(_component_a_select, {
      key: 0,
      class: "custom-select",
      placeholder: index === 0 ? '请选择省份' : index === 1 ? '请选择城市' : '请选择区县',
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      value: _ctx.optionsLevel[index].value,
      "onUpdate:value": function onUpdateValue($event) {
        return _ctx.optionsLevel[index].value = $event;
      },
      onChange: function onChange($event) {
        return _ctx.changeValue($event, index);
      },
      disabled: _ctx.disabled
    }, {
      default: _withCtx(function () {
        return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsLevel[index].options, function (option) {
          return _openBlock(), _createBlock(_component_a_select_option, {
            key: option.option_key,
            value: option.option_key
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(option.option), 1)];
            }),
            _: 2
          }, 1032, ["value"]);
        }), 128))];
      }),
      _: 2
    }, 1032, ["placeholder", "getPopupContainer", "value", "onUpdate:value", "onChange", "disabled"])) : (_openBlock(), _createBlock(_component_a_select, {
      key: 1,
      class: _normalizeClass(["custom-select", _ctx.isMobile ? 'mob-select' : 'select']),
      placeholder: _ctx.config.head[index],
      getPopupContainer: function getPopupContainer(triggerNode) {
        return triggerNode.parentNode;
      },
      value: _ctx.optionsLevel[index].value,
      "onUpdate:value": function onUpdateValue($event) {
        return _ctx.optionsLevel[index].value = $event;
      },
      onChange: function onChange($event) {
        return _ctx.changeValue($event, index);
      },
      disabled: _ctx.disabled
    }, {
      default: _withCtx(function () {
        return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsLevel[index].options, function (option) {
          return _openBlock(), _createBlock(_component_a_select_option, {
            key: option.option_key,
            value: option.option_key
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString(option.option), 1)];
            }),
            _: 2
          }, 1032, ["value"]);
        }), 128))];
      }),
      _: 2
    }, 1032, ["class", "placeholder", "getPopupContainer", "value", "onUpdate:value", "onChange", "disabled"]))], 2);
  }), 128))], 2);
}