import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import { message } from "ant-design-vue";
import { computed, defineComponent, ref } from "vue";
import Remark from "@/views/Answer/components/Remark";
export default defineComponent({
  props: {
    // 标题
    stem: {
      type: String,
      default: ""
    },
    // 题号
    title: {
      type: String,
      default: ""
    },
    // 错误
    error: {
      type: String,
      default: ""
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否作答模式
    isAnswer: {
      type: Boolean
    },
    // 题号
    questionIndex: {
      type: [String, Number],
      default: ''
    },
    // 题干
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    Remark: Remark
  },
  setup: function setup(props, context) {
    var marks = ref({}); // 刻度标记

    var questions = ref([]); // 问题列表

    var valueArr = computed(function () {
      return questions.value.filter(function (question) {
        return question.value !== undefined;
      });
    }); // 初始化

    function init() {
      // 设置刻度标记
      props.config.price_gradient.forEach(function (item) {
        marks.value[item] = item;
      }); // 设置问题列表

      questions.value = props.config.text_map.map(function (question) {
        return {
          title: question
        };
      });

      if (props.answer) {
        questions.value.forEach(function (question, index) {
          question.value = props.config.price_gradient[props.answer[index + 1] - 1];
        });
      }
    }

    init(); // 滑动回调

    function changeValue(value, item, i) {
      // 判断
      if (i === 4) {
        if (value > questions.value[1].value || value < questions.value[2].value) {
          item.value = 0;

          var _timer = setTimeout(function () {
            delete item.value;
            clearTimeout(_timer);
          });

          message.error("\u8BF7\u9009\u62E9\u4E0D\u5C0F\u4E8E".concat(questions.value[2].value, "\u4E0D\u5927\u4E8E").concat(questions.value[1].value, "\u7684\u503C"));
        }
      } else if (i !== 0) {
        if (value > questions.value[i - 1].value) {
          item.value = 0;
          message.error("\u8BF7\u9009\u62E9\u4E0D\u5927\u4E8E".concat(questions.value[i - 1].value, "\u7684\u503C"));
        }
      } // 重置


      questions.value.forEach(function (question, j) {
        if (j > i) {
          if (question.value) question.value = 0;

          var _timer2 = setTimeout(function () {
            delete question.value;
            clearTimeout(_timer2);
          });
        }
      });
      var timer = setTimeout(function () {
        // 更新答案
        var answer = {};
        var index = questions.value.findIndex(function (question, index) {
          var priceIndex = props.config.price_gradient.findIndex(function (price) {
            return price === question.value;
          });
          answer[index + 1] = priceIndex + 1;
          return question.value === undefined;
        });

        if (index === -1) {
          context.emit("update:answer", answer);
        } else {
          context.emit("update:answer", null);
        }

        clearTimeout(timer);
      });
    }

    return {
      marks: marks,
      questions: questions,
      valueArr: valueArr,
      changeValue: changeValue
    };
  }
});