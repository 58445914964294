import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-84f7cfc8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "checkbox-group"
};
var _hoisted_2 = {
  class: "option scrollbar answer-color"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_checkbox = _resolveComponent("a-checkbox");

  var _component_rich_text = _resolveComponent("rich-text");

  var _component_a_input = _resolveComponent("a-input");

  var _component_a_checkbox_group = _resolveComponent("a-checkbox-group");

  return _openBlock(), _createBlock(_component_a_checkbox_group, {
    value: _ctx.value,
    onChange: _ctx.changeValue,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option) {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "checkbox",
          key: option.option_key,
          style: _normalizeStyle(_ctx.isMobile ? 'width:100%' : "width: calc(100% / ".concat(_ctx.config.each_number, " - 32px)"))
        }, [_createVNode(_component_a_checkbox, {
          value: option.option_key
        }, null, 8, ["value"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_rich_text, {
          nodes: option.option,
          isPreview: ""
        }, null, 8, ["nodes"])]), option.is_other ? (_openBlock(), _createBlock(_component_a_input, {
          key: 0,
          value: option.value,
          "onUpdate:value": function onUpdateValue($event) {
            return option.value = $event;
          },
          onChange: function onChange($event) {
            return _ctx.changeInput($event, option.option_key);
          }
        }, null, 8, ["value", "onUpdate:value", "onChange"])) : _createCommentVNode("", true)], 4)), [[_vShow, !_ctx.hideOptions.includes(option.option_key)]]);
      }), 128))])];
    }),
    _: 1
  }, 8, ["value", "onChange", "disabled"]);
}