import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var options = ref([]); // 选项

    var previewTime = ref(0); // 倒计时长
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });
      options.value.forEach(function (option) {
        option.preview = true;
        option.visible = false;
      });
      context.emit("update:answer", {});
    }

    init(); // 预览图片

    function previewImg(option) {
      if (option.preview && !previewTime.value) {
        option.visible = true;
        previewTime.value = props.config.countdown_time;
        var timer = setInterval(function () {
          if (previewTime.value === 0) {
            clearInterval(timer);
            option.preview = false;
            option.visible = false;
          } else {
            previewTime.value -= 1;
          }
        }, 1000);
      }
    } // 关闭图片


    function setVisible(option) {
      previewTime.value = 0;
      option.preview = false;
      option.visible = false;
    }

    return {
      options: options,
      previewTime: previewTime,
      previewImg: previewImg,
      setVisible: setVisible
    };
  }
});