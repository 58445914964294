import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import { defineComponent, ref, watch } from "vue";
import draggable from "vuedraggable";
export default defineComponent({
  components: {
    draggable: draggable
  },
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 组名
    var groupName = "sort_".concat(new Date().getTime(), "_").concat(Math.floor(Math.random() * 1000)); // 选项

    var sort = ref([]);
    var options = ref([]); // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });

      if (props.answer) {
        var answerArr = [];
        Object.keys(props.answer).forEach(function (key) {
          answerArr[props.answer[key] - 1] = key;
        });
        answerArr.forEach(function (optionKey) {
          if (optionKey) {
            var index = options.value.findIndex(function (option) {
              return option.option_key === optionKey;
            });
            sort.value.push(options.value.splice(index, 1)[0]);
          }
        });
        updateAnswer();
      }
    }

    init(); // 监听拖动事件

    watch([sort], function () {
      updateAnswer();
    }, {
      deep: true // 开启深度监视,

    }); // 更新答案

    function updateAnswer() {
      // 更新答案
      if (props.config.sort_scope && sort.value.length === props.config.sort_scope || !props.config.sort_scope && !options.value.length) {
        var answer = {};
        sort.value.forEach(function (item, index) {
          answer[item.option_key] = index + 1;
        });
        context.emit("update:answer", answer);
      } else {
        context.emit("update:answer", null);
      }
    }

    function onMove(e) {
      if (props.config.sort_scope && sort.value.length === props.config.sort_scope) return false;
      return true;
    }

    return {
      groupName: groupName,
      options: options,
      sort: sort,
      onMove: onMove
    };
  }
});