import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-29fadcbc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "avatar"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  key: 1,
  class: "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.avatar ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.avatar,
    alt: "user-avatar"
  }, null, 8, _hoisted_2)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.name), 1));
}