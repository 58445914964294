import "core-js/modules/es.number.constructor.js";
import { defineComponent } from "vue";
import RemarkItem from "@/views/Answer/components/Remark/RemarkItem";
import SubTitle from "@/views/DataAnalyse/components/SubTitle";
export default defineComponent({
  props: {
    groupData: {
      type: Object,
      default: function _default() {}
    },
    sn: {
      type: String,
      default: ''
    },
    type: {
      type: [String, Number],
      default: undefined
    },
    userId: {
      type: Number,
      default: undefined
    },
    isAllComment: {
      type: Boolean,
      default: false
    },
    isSolve: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RemarkItem: RemarkItem,
    SubTitle: SubTitle
  },
  emits: ['addReply', 'delReply', 'readComment', 'addComment', 'delComment'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    function addReply(data) {
      emit('addReply', data);
    }

    function delReply(id) {
      emit('delReply', id);
    }

    function addComment(data) {
      emit('addComment', data);
    }

    function delComment(id) {
      emit('delComment', id);
    }

    function readComment(id) {
      emit('readComment', id);
    }

    function unreadComment(id) {
      emit('unreadComment', id);
    }

    return {
      addReply: addReply,
      delReply: delReply,
      addComment: addComment,
      delComment: delComment,
      readComment: readComment,
      unreadComment: unreadComment
    };
  }
});