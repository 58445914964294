import "core-js/modules/es.number.constructor.js";
import { ref, defineComponent } from 'vue';
import ReplyItem from "@/views/Answer/components/Remark/ReplyItem";
import RemarkEditor from "@/views/Answer/components/Remark/RemarkEditor";
import avatar from "@/views/Answer/components/Remark/avatar";
import { CheckCircleFilled } from "@ant-design/icons-vue";
import { Popover } from "ant-design-vue";
export default defineComponent({
  props: {
    info: {
      type: Object,
      default: function _default() {}
    },
    userId: {
      type: Number,
      default: undefined
    },
    isSolve: {
      type: Boolean,
      default: false
    },
    isAllComment: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RemarkEditor: RemarkEditor,
    ReplyItem: ReplyItem,
    avatar: avatar,
    CheckCircleFilled: CheckCircleFilled,
    Popover: Popover
  },
  emits: ['delReply', 'addReply', 'addComment', 'delComment', 'readComment', 'unreadComment'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var visible = ref(false);
    var isImageStyle = ref(false);
    var content = ref('');
    var isRenderEditor = ref(false);
    /**
     * 打开回复编辑器
     */

    function OpenCommentEditor() {
      isRenderEditor.value = true;
    }
    /**
     * 关闭编辑器 清空内容
     */


    function handleCancel() {
      isRenderEditor.value = false;
      content.value = '';
    }
    /**
     * 发送评论内容
     */


    function sendCommentMessage() {
      emit('addComment', {
        comment_id: props.info.id,
        content: content.value,
        type: props.isAllComment ? props.info.type : null,
        question_index: props.isAllComment ? props.info.question_index : null
      });
      handleCancel();
    }
    /**
     * 删除评论
     */


    function delCommentMessage(id) {
      emit('delComment', id);
    }
    /**
     * 已读评论
     */


    function handleOnRead(id) {
      emit('readComment', id);
    }

    function handleUnread(id) {
      emit('unreadComment', id);
    }
    /**
     *
     * 删除回复
     */


    function delReply(id) {
      emit('delReply', id);
    }
    /**
     * 添加回复
     */


    function addReply(data) {
      emit('addReply', data);
    }

    return {
      visible: visible,
      isImageStyle: isImageStyle,
      content: content,
      isRenderEditor: isRenderEditor,
      handleCancel: handleCancel,
      handleOnRead: handleOnRead,
      handleUnread: handleUnread,
      OpenCommentEditor: OpenCommentEditor,
      delReply: delReply,
      addReply: addReply,
      sendCommentMessage: sendCommentMessage,
      delCommentMessage: delCommentMessage
    };
  }
});