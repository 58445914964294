import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0f4a1894"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "password"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_input = _resolveComponent("a-input");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_a_input, {
    type: "password",
    maxlength: _ctx.config.max,
    placeholder: _ctx.config.password_placeholder,
    value: _ctx.password1,
    "onUpdate:value": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.password1 = $event;
    }),
    onChange: _ctx.updateAnswer,
    disabled: _ctx.disabled,
    autocomplete: "new-password"
  }, null, 8, ["maxlength", "placeholder", "value", "onChange", "disabled"])]), _createElementVNode("div", null, [_createVNode(_component_a_input, {
    type: "password",
    maxlength: _ctx.config.max,
    placeholder: _ctx.config.againpassword_placeholder,
    value: _ctx.password2,
    "onUpdate:value": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.password2 = $event;
    }),
    onChange: _ctx.updateAnswer,
    disabled: _ctx.disabled,
    autocomplete: "new-password"
  }, null, 8, ["maxlength", "placeholder", "value", "onChange", "disabled"])])]);
}