import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
import PfePagination from "@/components/PfePagination.vue";
import { defineComponent, ref, watch } from "vue";
import { message } from "ant-design-vue";
import Remark from "@/views/Answer/components/Remark";
export default defineComponent({
  components: {
    PfePagination: PfePagination,
    Remark: Remark
  },
  props: {
    // 错误
    error: {
      type: String,
      default: ""
    },
    // 标题
    title: {
      type: String,
      default: ""
    },
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 版本
    version: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 是否作答模式
    isAnswer: {
      type: Boolean,
      default: false
    },
    // 题号
    questionIndex: {
      type: [String, Number],
      default: ""
    },
    // 题干
    label: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props, context) {
    var page = ref(1);
    var pages = ref(1);
    var options = ref([]); // 选项

    var allOptions = ref([]); // 全部选项
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });
      allOptions.value = props.version.options.map(function (versionOptions) {
        return JSON.parse(JSON.stringify(versionOptions.map(function (optionKey) {
          return options.value.find(function (option) {
            return option.option_key == optionKey;
          });
        })));
      });
      pages.value = allOptions.value.length;

      if (props.answer) {
        // 如果为作答模式从断点开始继续
        if (props.isAnswer) {
          page.value = props.answer.length;
        }

        props.answer.forEach(function (_ref, index) {
          var concept = _ref.concept;
          var w = concept.w,
              b = concept.b;
          var currentOptions = allOptions.value[index];
          currentOptions.forEach(function (option) {
            if (option.option_key === w) {
              option.value = "w";
            } else if (option.option_key === b) {
              option.value = "b";
            }
          });
        });
      }
    }

    init(); // 改变当前页值

    function changeValue(e, option) {
      var value = e.target.value;
      var currentOptions = allOptions.value[page.value - 1];
      var currentOption = currentOptions.find(function (option) {
        return option.value === value;
      });

      if (currentOption) {
        currentOption.value = "";
      }

      option.value = value;
    } // 上一页


    function previous() {
      if (page.value === 1) {
        return context.emit("previous");
      }

      page.value -= 1;
    } // 下一页


    function next() {
      // 作答模式下不进行选择判断
      if (!props.isAnswer) {
        if (page.value === pages.value) {
          return context.emit("next");
        }

        return page.value += 1;
      } // 当前页答案


      var currentOptions = allOptions.value[page.value - 1];

      if (currentOptions.findIndex(function (option) {
        return option.value === "b";
      }) === -1) {
        return message.error("请选择高相关");
      }

      if (currentOptions.findIndex(function (option) {
        return option.value === "w";
      }) === -1) {
        return message.error("请选择不相关");
      } // 最后一页


      if (page.value === pages.value) {
        return updateAnswer();
      } // 下一页


      page.value += 1;
    } // 更新答案


    function updateAnswer() {
      var answer = allOptions.value.map(function (options, index) {
        return {
          task: index + 1,
          version: props.version.version,
          design_version: props.version.design_version,
          concept: {
            b: options.find(function (option) {
              return option.value === "b";
            }).option_key,
            w: options.find(function (option) {
              return option.value === "w";
            }).option_key
          }
        };
      });
      context.emit("update:answer", answer);
      context.emit("next");
    } // 查找数组中某元素的个数


    function findEleCount(ele) {
      return value.value.slice(0, page.value - 1).filter(function (val) {
        return val === ele;
      }).length;
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        options: allOptions.value
      });
    }, {
      deep: true,
      immediate: true
    });
    return {
      page: page,
      pages: pages,
      options: options,
      allOptions: allOptions,
      changeValue: changeValue,
      previous: previous,
      next: next,
      findEleCount: findEleCount
    };
  }
});