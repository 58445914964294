import request from "@/utils/request";
const prefix = `/console`

// 获取评论列表
export function getCommentList(sn, params) {
  return request({
    url: `${prefix}/surveys/${sn}/comments`,
    method: 'get',
    params
  })
}

// 添加评论
export function postComment(sn, data) {
  return request({
    url: `${prefix}/surveys/${sn}/comments`,
    method: 'post',
    data
  })
}

// 评论标记已读
export function updateComment(sn, id, status) {
  return request({
    url: `${prefix}/surveys/${sn}/comments/${id}?status=${status}`,
    method: 'put',
  })
}

// 删除评论
export function removeComment(sn, comment_id) {
  return request({
    url: `${prefix}/surveys/${sn}/comments/${comment_id}`,
    method: 'delete',
  })
}
