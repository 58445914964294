import _defineProperty from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.keys.js";
import { defineComponent, ref, watch } from "vue";
import MRate from "./components/MRate.vue";
export default defineComponent({
  components: {
    MRate: MRate
  },
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 选项
    var row = ref([]);
    var col = ref([]);
    var value = ref({}); // 值
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        if (list.type === 1) {
          row.value = [].concat(_toConsumableArray(row.value), _toConsumableArray(list.options));
        } else if (list.type === 2) {
          col.value = [].concat(_toConsumableArray(col.value), _toConsumableArray(list.options));
        }
      });

      if (props.answer) {
        value.value = props.answer;
        updateAnswer(props.answer);
      }
    }

    init(); // 打分回调

    function changeValue(val, key) {
      if (props.disabled) return;
      console.log("打分回调");

      var newValue = _objectSpread(_objectSpread({}, value.value), _defineProperty({}, key, val));

      value.value = newValue;
      updateAnswer(newValue);
    } // 更新答案


    function updateAnswer(newValue) {
      var isAnswer = row.value.findIndex(function (rowItem) {
        return col.value.findIndex(function (colItem) {
          return !newValue["".concat(rowItem.option_key, "_").concat(colItem.option_key)];
        }) !== -1;
      });

      if (isAnswer === -1 || !props.config.is_required) {
        context.emit("update:answer", newValue);
      } else {
        context.emit("update:answer", null);
      }
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        list: props.list
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      // 更新关联题选项
      var newRow = [];
      var newCol = [];
      props.list.forEach(function (list) {
        if (list.type === 1) {
          newRow = [].concat(_toConsumableArray(newRow), _toConsumableArray(list.options));
        } else if (list.type === 2) {
          newCol = [].concat(_toConsumableArray(newCol), _toConsumableArray(list.options));
        }
      });
      var keys = [];
      var newValue = value.value;
      newRow.forEach(function (rowItem) {
        newCol.forEach(function (colItem) {
          keys.push("".concat(rowItem.option_key, "_").concat(colItem.option_key));
        });
      });
      Object.keys(newValue).forEach(function (val) {
        if (keys.findIndex(function (key) {
          return key === val;
        }) === -1) {
          delete newValue[val];
        }
      });
      row.value = newRow;
      col.value = newCol; // 清空值和答案

      value.value = newValue;
      updateAnswer(newValue);
    }, {
      deep: true
    }); // 是否禁用选项

    function isDisabled(rowKey, colKey) {
      var _props$config;

      var index = (((_props$config = props.config) === null || _props$config === void 0 ? void 0 : _props$config.disabled) || []).findIndex(function (item) {
        return item.row_key === rowKey && item.cell_key === colKey;
      });
      return index === -1 ? false : true;
    }

    return {
      row: row,
      col: col,
      value: value,
      changeValue: changeValue,
      isDisabled: isDisabled
    };
  }
});