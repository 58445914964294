import _defineProperty from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import PfePagination from "@/components/PfePagination.vue";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import Remark from "@/views/Answer/components/Remark";
export default defineComponent({
  components: {
    PfePagination: PfePagination,
    Remark: Remark
  },
  props: {
    // 错误
    error: {
      type: String,
      default: ""
    },
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 标题
    stem: {
      type: String,
      default: ""
    },
    // 题号
    title: {
      type: String,
      default: ""
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    // 是否作答模式
    isAnswer: {
      type: Boolean
    },
    // 题号
    questionIndex: {
      type: [String, Number],
      default: ''
    },
    // 题干
    label: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, context) {
    var page = ref(1);
    var pages = ref(1);
    var value = ref([]);
    var price = ref([]);
    var options = ref([]); // 选项
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      }); // 计算总页数

      var priceLength = options.value[0].children.length;
      pages.value = options.value.length * (priceLength - 1) + 1;

      if (props.answer) {
        page.value = props.answer.length;
        props.answer.forEach(function (answerItem) {
          Object.keys(answerItem).forEach(function (key) {
            if (key === "0") {
              value.value.push(0);
              price.value.push(0);
            } else if (key !== "task" && answerItem[key] === 1) {
              value.value.push(key);
            } else if (key !== "task" && answerItem[key] === 2) {
              price.value.push(key);
            }
          });
        });
      }
    }

    init(); // 改变当前页值

    function changeValue(optionKey) {
      var _option$children, _option$children$find;

      if (props.disabled) return; // 值

      value.value[page.value - 1] = optionKey; // 价格

      var option = options.value.find(function (option) {
        return option.option_key === optionKey;
      });
      price.value[page.value - 1] = (option === null || option === void 0 ? void 0 : (_option$children = option.children) === null || _option$children === void 0 ? void 0 : (_option$children$find = _option$children[findEleCount(optionKey)]) === null || _option$children$find === void 0 ? void 0 : _option$children$find.option_key) || 0;
    } // 上一页


    function previous() {
      if (page.value === 1) {
        return context.emit("previous");
      }

      page.value -= 1;
    } // 下一页


    function next() {
      // 最后一页
      if (page.value === pages.value) {
        return updateAnswer();
      }

      var lastValue = value.value[page.value - 1]; // 以上都不选

      if (lastValue === 0) {
        return updateAnswer();
      } // 当前页没有选择


      if (lastValue === undefined) {
        return message.error("请选择");
      }

      var count = value.value.slice(0, page.value).filter(function (val) {
        return val === lastValue;
      }).length; // 当前产品已答完

      if (count === options.value[0].children.length) {
        return updateAnswer();
      } // 下一页


      page.value += 1;
    } // 更新答案


    function updateAnswer() {
      var answer = value.value.slice(0, page.value).map(function (optionKey, index) {
        var _answerItem;

        var answerItem = (_answerItem = {}, _defineProperty(_answerItem, optionKey, 1), _defineProperty(_answerItem, "task", index + 1), _answerItem);

        if (optionKey !== 0) {
          answerItem[price.value[index]] = 2;
        }

        return answerItem;
      });
      context.emit("update:answer", answer);
      context.emit("next");
    } // 查找数组中某元素的个数


    function findEleCount(ele) {
      return value.value.slice(0, page.value - 1).filter(function (val) {
        return val === ele;
      }).length;
    }

    return {
      page: page,
      pages: pages,
      value: value,
      price: price,
      options: options,
      changeValue: changeValue,
      previous: previous,
      next: next,
      findEleCount: findEleCount
    };
  }
});