import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9a9fcde2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "question-introduce-container"
};
var _hoisted_2 = {
  key: 0,
  class: "title-part"
};
var _hoisted_3 = {
  key: 1,
  class: "desc-part"
};
var _hoisted_4 = {
  key: 0,
  style: {
    "margin-top": "32px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_rich_text = _resolveComponent("rich-text");

  var _component_Remark = _resolveComponent("Remark");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.showTitle ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_rich_text, {
    isPreview: "",
    nodes: _ctx.title,
    class: _normalizeClass(["title", _ctx.isMobile ? 'm-title' : '']),
    style: _normalizeStyle("color: ".concat(_ctx.themeColor.stemColor))
  }, null, 8, ["nodes", "class", "style"]), !_ctx.isAnswer ? (_openBlock(), _createBlock(_component_Remark, {
    key: 0,
    title: _ctx.label + '标题评论',
    type: 1
  }, null, 8, ["title"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _ctx.showDesc ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_rich_text, {
    isPreview: "",
    nodes: _ctx.desc,
    class: _normalizeClass(_ctx.isMobile ? 'm-desc' : 'desc'),
    style: _normalizeStyle("color: ".concat(_ctx.themeColor.stemColor))
  }, null, 8, ["nodes", "class", "style"]), _ctx.desc ? (_openBlock(), _createElementBlock("div", _hoisted_4, [!_ctx.isAnswer ? (_openBlock(), _createBlock(_component_Remark, {
    key: 0,
    title: _ctx.label + '介绍语评论',
    type: 2
  }, null, 8, ["title"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}