import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.replace-all.js";
import RichText from "@/components/RichText.vue";
import { computed, defineComponent, inject } from "vue";
import Remark from "../components/Remark/index.vue";

var __default__ = defineComponent({
  components: {
    RichText: RichText,
    Remark: Remark
  },
  props: {
    // 标题
    stem: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    // 标题
    tip: {
      type: String,
      default: ""
    },
    // 错误
    error: {
      type: String,
      default: ""
    },
    // 题型
    questionType: {
      type: Number,
      default: 0
    },
    // 题号
    questionIndex: {
      type: Number,
      default: 0
    },
    // 问卷列表
    questions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否显示题号
    showTitle: {
      type: [Boolean, Number],
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否答题模式
    isAnswer: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _themeColor$value, _themeColor$value2, _themeColor$value3;

    var themeColor = inject("themeColor"); // 主题颜色

    var buttonColor = (_themeColor$value = themeColor.value) === null || _themeColor$value === void 0 ? void 0 : _themeColor$value.buttonColor; // 按钮颜色

    var buttonTextColor = (_themeColor$value2 = themeColor.value) === null || _themeColor$value2 === void 0 ? void 0 : _themeColor$value2.buttonTextColor; // 按钮文字颜色

    var answerColor = ((_themeColor$value3 = themeColor.value) === null || _themeColor$value3 === void 0 ? void 0 : _themeColor$value3.answerColor) || "#333333"; // 答案颜色

    var answerColor30 = toRgba(answerColor, 0.3); // 背景颜色(透明度30s)

    var answerColor10 = toRgba(answerColor, 0.1); // 背景颜色(透明度10)

    var answerBorder = answerColor === "#333333" ? "#d9d9d9" : answerColor; // 边框颜色

    var answerPlaceholder = toRgba(answerColor, 0.3); // placeholder颜色
    // 16进制转rgba

    function toRgba(color, opacity) {
      return "rgba(" + parseInt(color.substring(1, 3), 16) + "," + parseInt(color.substring(3, 5), 16) + "," + parseInt(color.substring(5, 7), 16) + "," + opacity + ")";
    }

    var newTitle = computed(function () {
      var title = props.stem;
      var matchArr = title.match(/(\[%cite\(.*?\)%\])/g) || [];
      matchArr.forEach(function (matchValue) {
        var value = matchValue.replace("[%cite(", "").replace(")%]", "");
        var replacement = ""; // 替换文本
        // 查找引用问题

        var question = props.questions.find(function (question) {
          // 矩阵题
          if (question.question_type >= 8 && question.question_type <= 11) {
            return question.title === value.split("_R")[0].split("_C")[0];
          } // 排序题


          if (question.question_type === 16) {
            return question.title === value.split("_A")[0];
          }

          return question.title === value;
        });

        if (question) {
          var options = []; // 选项

          question.list.forEach(function (list) {
            options = [].concat(_toConsumableArray(options), _toConsumableArray(list.options));
          });

          if (question.answer) {
            var answer = question.answer;

            if (question.question_type === 1) {
              // 查找引用选项(单选)
              var option = options.find(function (option) {
                return option.option_key === Object.keys(answer)[0];
              });

              if (answer[option.option_key] === "1") {
                replacement = option.option;
              } else {
                replacement = answer[option.option_key];
              }
            } else if (question.question_type === 2 && Object.keys(answer).length >= question.config.min_select) {
              // 查找引用选项(多选)
              options.forEach(function (option) {
                if (answer[option.option_key] === "1") {
                  replacement += option.option;
                } else if (answer[option.option_key]) {
                  replacement += answer[option.option_key];
                }
              });
            } else if (question.question_type === 4) {
              // 查找引用选项(填空)
              replacement = answer.value;
            } else if (question.question_type === 5 && options.length > 1) {
              // 查找引用选项(打分)
              options.forEach(function (option) {
                replacement += option.option;
              });
            } else if (question.question_type === 17) {
              // 查找引用选项(恒定总和)
              options.forEach(function (option) {
                replacement += option.option;
              });
            } else if (question.question_type >= 8 && question.question_type <= 11) {// 查找引用选项(矩阵题)
              // const val = value.split("_").reverse()[0];
              // let row = [];
              // let col = [];
              // question.list.forEach((list) => {
              //   if (list.type === 1) {
              //     row = [...row, ...list.options];
              //   } else if (list.type === 2) {
              //     col = [...col, ...list.options];
              //   }
              // });
              // if (val[0] === "R") {
              //   col.forEach((colItem) => {
              //     replacement += colItem.option;
              //   });
              // } else if (val[0] === "C") {
              //   row.forEach((rowItem) => {
              //     replacement += rowItem.option;
              //   });
              // }
            } else if (question.question_type === 16) {
              // 查找引用选项(排序)
              var optionKey;
              var sort = value.split("_A")[1] * 1;
              Object.keys(answer).forEach(function (key) {
                if (answer[key] === sort) {
                  optionKey = key;
                }
              });

              var _option = options.find(function (option) {
                return option.option_key === optionKey;
              });

              replacement += (_option === null || _option === void 0 ? void 0 : _option.option) || "";
            }
          }
        }

        title = title.replace("".concat(matchValue), replacement).replaceAll("<p ", "<span ").replaceAll("<p>", "<span>").replaceAll("</p>", "</span>");
      });
      return title;
    });
    return {
      newTitle: newTitle,
      buttonColor: buttonColor,
      buttonTextColor: buttonTextColor,
      themeColor: themeColor,
      answerColor: answerColor,
      answerColor30: answerColor30,
      answerColor10: answerColor10,
      answerBorder: answerBorder,
      answerPlaceholder: answerPlaceholder
    };
  }
});

import { useCssVars as _useCssVars } from 'vue';

var __injectCSSVars__ = function __injectCSSVars__() {
  _useCssVars(function (_ctx) {
    return {
      "dc575a5c": _ctx.answerColor,
      "b1f882c6": _ctx.answerBorder,
      "f7f9ccbc": _ctx.answerPlaceholder,
      "6e0aecaf": _ctx.answerColor30,
      "6e0aec71": _ctx.answerColor10,
      "8083859e": _ctx.buttonTextColor,
      "3b86f3de": _ctx.buttonColor
    };
  });
};

var __setup__ = __default__.setup;
__default__.setup = __setup__ ? function (props, ctx) {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;