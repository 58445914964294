import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import { defineComponent, inject } from "vue";
import Remark from "@/views/Answer/components/Remark";
export default defineComponent({
  props: {
    code: {
      type: Number,
      default: 0
    },
    survey: {
      type: Object,
      default: function _default() {}
    },
    // 是否答题模式
    isAnswer: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Remark: Remark
  },
  setup: function setup(props) {
    var questionsData = inject("questionsData"); // 问卷数据
    // 答题模式

    if (props.isAnswer) {
      switch (props.code) {
        case 20004:
          // 被甄别
          if (props.survey.screening_end_url_select && props.survey.screening_end_url) {
            var timer = setTimeout(function () {
              var url = props.survey.screening_end_url;
              toUrl(url);
              clearTimeout(timer);
            }, props.survey.screening_standing_time * 1000);
          }

          break;

        case 20011:
          // 成功
          if (props.survey.success_end_url_select && props.survey.success_end_url) {
            var _timer = setTimeout(function () {
              var url = props.survey.success_end_url;
              toUrl(url);
              clearTimeout(_timer);
            }, props.survey.success_standing_time * 1000);
          }

          break;

        case 20016:
          // 配额超限
          if (props.survey.quota_end_url_select && props.survey.quota_end_url) {
            var _timer2 = setTimeout(function () {
              var url = props.survey.quota_end_url;
              toUrl(url);
              clearTimeout(_timer2);
            }, props.survey.quota_standing_time * 1000);
          }

          break;

        default:
          break;
      }
    } // 跳转链接


    function toUrl(url) {
      url = url.replaceAll("#sn#", questionsData.value.answer.sn); // 判断是否小程序路径

      if (url[0] === "/") {
        // 判断是否在小程序环境
        wx.miniProgram.getEnv(function () {
          wx.miniProgram.redirectTo({
            url: url
          });
        });
      } else {
        if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
          url = "http://".concat(url);
        }

        open(url);
      }
    }

    return {
      questionsData: questionsData
    };
  }
});