import "core-js/modules/es.array.concat.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3ed8611e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "iconfont left-icon"
};
var _hoisted_2 = {
  key: 1,
  class: "iconfont right-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pfe-button", "type-".concat(_ctx.type, " size-").concat(_ctx.size)])
  }, [_ctx.icon ? (_openBlock(), _createElementBlock("i", _hoisted_1, _toDisplayString(_ctx.icon), 1)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_ctx.text), 1), _ctx.rightIcon ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(_ctx.rightIcon), 1)) : _createCommentVNode("", true)], 2);
}