import "core-js/modules/es.array.includes.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f43f624e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  class: "rate-box"
};
var _hoisted_3 = {
  key: 0,
  class: "rate"
};
var _hoisted_4 = {
  key: 0,
  class: "icon iconfont icon-star-fill"
};
var _hoisted_5 = {
  key: 1,
  class: "icon iconfont icon-heart-fill"
};
var _hoisted_6 = {
  key: 2,
  class: "icon iconfont icon-smile-fill"
};
var _hoisted_7 = {
  key: 3,
  class: "icon iconfont icon-thumb-fill"
};
var _hoisted_8 = {
  key: 1,
  class: "rate"
};
var _hoisted_9 = {
  key: 2,
  class: "rate"
};
var _hoisted_10 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_rate = _resolveComponent("a-rate");

  var _component_rich_rate_item = _resolveComponent("rich-rate-item");

  var _component_slider_num_rate = _resolveComponent("slider-num-rate");

  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["option scrollbar", _ctx.isMobile ? 'mobile-option' : '']),
      key: option.option_key
    }, [_ctx.options.length > 1 ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "title",
      innerHTML: option.option
    }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_ctx.config.score_way === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [[0, 2, 3, 4].includes(_ctx.config.score_type) ? (_openBlock(), _createBlock(_component_a_rate, {
      key: 0,
      class: "rich-rate custom-rate",
      style: _normalizeStyle({
        color: _ctx.getColor()
      }),
      value: option.value,
      "onUpdate:value": function onUpdateValue($event) {
        return option.value = $event;
      },
      count: (_ctx.config.max - _ctx.config.min) / _ctx.config.score_interval + 1,
      allowClear: false,
      onChange: _ctx.updateAnswer,
      disabled: _ctx.disabled
    }, {
      character: _withCtx(function () {
        return [_ctx.config.score_type === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_4)) : _ctx.config.score_type === 2 ? (_openBlock(), _createElementBlock("span", _hoisted_5)) : _ctx.config.score_type === 3 ? (_openBlock(), _createElementBlock("span", _hoisted_6)) : _ctx.config.score_type === 4 ? (_openBlock(), _createElementBlock("span", _hoisted_7)) : _createCommentVNode("", true)];
      }),
      _: 2
    }, 1032, ["style", "value", "onUpdate:value", "count", "onChange", "disabled"])) : (_openBlock(), _createBlock(_component_rich_rate_item, {
      key: 1,
      color: _ctx.getColor(),
      count: (_ctx.config.max - _ctx.config.min) / _ctx.config.score_interval + 1,
      type: _ctx.config.score_type === 5 ? 'step' : 'number',
      value: option.value,
      "onUpdate:value": [function ($event) {
        return option.value = $event;
      }, _ctx.updateAnswer],
      style: {
        "margin": "20px 0 6px"
      }
    }, null, 8, ["color", "count", "type", "value", "onUpdate:value"]))])) : _ctx.config.score_way === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_slider_num_rate, {
      rateType: "num",
      param: {
        interval: _ctx.config.score_interval,
        max: _ctx.config.max,
        min: _ctx.config.min
      },
      value: option.value,
      "onUpdate:value": [function ($event) {
        return option.value = $event;
      }, _ctx.updateAnswer],
      style: {
        "margin-bottom": "6px"
      }
    }, null, 8, ["param", "value", "onUpdate:value"])])) : _ctx.config.score_way === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_slider_num_rate, {
      rateType: "slider",
      param: {
        interval: _ctx.config.score_interval,
        max: _ctx.config.max,
        min: _ctx.config.min
      },
      value: option.value,
      "onUpdate:value": [function ($event) {
        return option.value = $event;
      }, _ctx.updateAnswer]
    }, null, 8, ["param", "value", "onUpdate:value"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(_ctx.config.prompt_left), 1), _createElementVNode("span", null, _toDisplayString(_ctx.config.prompt_center), 1), _createElementVNode("span", null, _toDisplayString(_ctx.config.prompt_right), 1)])])], 2);
  }), 128))]);
}