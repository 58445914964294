import { defineComponent } from "vue";
export default defineComponent({
  props: {
    type: {
      type: String,
      default: "default"
    },
    size: {
      type: String,
      default: "default"
    },
    text: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    rightIcon: {
      type: String,
      default: ""
    }
  },
  setup: function setup() {
    return {};
  }
});