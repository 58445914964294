import { defineComponent, ref } from "vue";
import { stringToMoment } from "ant-design-vue/lib/_util/moment-util";
export default defineComponent({
  props: {
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var date = ref(""); // 值

    var time = ref(""); // 值
    // 初始化

    function init() {
      if (props.answer) {
        date.value = props.answer.date;
        time.value = props.answer.time;
      }
    }

    init(); // 选择年份回调

    function changeYear(e) {
      var yearStr = e._d.getFullYear() + "";
      date.value = yearStr;
      updateAnswer(yearStr, time.value);
    } // 选择日期回调


    function changeDate(e, dateStr) {
      date.value = dateStr;
      updateAnswer(dateStr, time.value);
    } // 选择时间回调


    function changeTime(e, timeStr) {
      time.value = timeStr;
      updateAnswer(date.value, timeStr);
    } // 更新答案


    function updateAnswer(newDate, newTime) {
      if (newDate && newTime) {
        context.emit("update:answer", {
          date: "".concat(newDate),
          time: "".concat(newTime)
        });
      } else if (newDate && !props.config.time) {
        context.emit("update:answer", {
          date: "".concat(newDate)
        });
      } else if (newTime && !props.config.date) {
        context.emit("update:answer", {
          time: "".concat(newTime)
        });
      } else {
        context.emit("update:answer", null);
      }
    }

    return {
      date: date,
      time: time,
      changeYear: changeYear,
      changeDate: changeDate,
      changeTime: changeTime,
      stringToMoment: stringToMoment
    };
  }
});