import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  class: "comment-group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_sub_title = _resolveComponent("sub-title");

  var _component_remark_item = _resolveComponent("remark-item");

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.isAllComment ? (_openBlock(), _createBlock(_component_sub_title, {
    key: 0
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.groupData.title), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupData.data, function (item, key) {
    return _openBlock(), _createBlock(_component_remark_item, {
      key: key,
      info: item,
      sn: _ctx.sn,
      type: _ctx.type,
      userId: _ctx.userId,
      "is-solve": _ctx.isSolve,
      isAllComment: _ctx.isAllComment,
      onDelReply: _ctx.delReply,
      onAddReply: _ctx.addReply,
      onAddComment: _ctx.addComment,
      onDelComment: _ctx.delComment,
      onReadComment: _ctx.readComment,
      onUnreadComment: _ctx.unreadComment
    }, null, 8, ["info", "sn", "type", "userId", "is-solve", "isAllComment", "onDelReply", "onAddReply", "onAddComment", "onDelComment", "onReadComment", "onUnreadComment"]);
  }), 128))], 512)), [[_vShow, _ctx.groupData && _ctx.groupData.data]]);
}