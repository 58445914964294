import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
export default defineComponent({
  props: {
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var value = ref(""); // 值
    // 初始化

    function init() {
      if (props.answer) {
        value.value = props.answer.value;
      }
    }

    init(); // 输入回调

    function changeValue(e) {
      var val = e.target.value; // 整数限制

      if (props.config.text_type === 1 && !/^-?[0-9]\d*$/.test(val) && val) {
        val = value.value;
      } // 小数位数限制


      if (props.config.text_type === 2) {
        var _val$split$;

        if (((_val$split$ = val.split(".")[1]) === null || _val$split$ === void 0 ? void 0 : _val$split$.length) > props.config.decimal_few) {
          val = value.value;
        }
      } // 数值限制(最大)


      if (val && props.config.max !== "" && val > props.config.max && [1, 2].includes(props.config.text_type)) {
        val = value.value;
      } // 长度限制(最大)


      if (props.config.max && val.length > props.config.max && [0, 3, 4].includes(props.config.text_type)) {
        val = value.value;
      }

      value.value = val;
      context.emit("changeValue", val); // 更新答案

      if (val) {
        context.emit("update:answer", {
          value: val
        });
      } else {
        context.emit("update:answer", null);
      }
    } // 数值限制


    function imposeNum() {
      var val = value.value;

      if (!val) {
        return message.error("\u8BF7\u8F93\u5165\u6570\u5B57");
      }

      if (val && val < props.config.min) {
        changeValue({
          target: {
            value: ""
          }
        });
        message.error("\u8BF7\u8F93\u5165\u5927\u4E8E\u7B49\u4E8E".concat(props.config.min, "\u7684\u6570\u5B57"));
      }

      if (val && val < props.config.min) {
        changeValue({
          target: {
            value: ""
          }
        });
        message.error("\u8BF7\u8F93\u5165\u5927\u4E8E\u7B49\u4E8E".concat(props.config.min, "\u7684\u6570\u5B57"));
      }
    }

    return {
      value: value,
      changeValue: changeValue,
      imposeNum: imposeNum
    };
  }
});