import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-579d28d6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "question-concluse-wrapper"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = {
  key: 0,
  class: "comment"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Remark = _resolveComponent("Remark");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.code === 20004 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    innerHTML: _ctx.survey.screening_end_content
  }, null, 8, _hoisted_3)) : _ctx.code === 20011 ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    innerHTML: _ctx.survey.success_end_content
  }, null, 8, _hoisted_4)) : _ctx.code === 20016 ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    innerHTML: _ctx.survey.quota_end_content
  }, null, 8, _hoisted_5)) : _createCommentVNode("", true)]), !_ctx.isAnswer ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_ctx.code === 20004 ? (_openBlock(), _createBlock(_component_Remark, {
    key: 0,
    title: "结束语提前结束评论",
    type: 6
  })) : _createCommentVNode("", true), _ctx.code === 20011 ? (_openBlock(), _createBlock(_component_Remark, {
    key: 1,
    title: "结束语成功完成评论",
    type: 5
  })) : _createCommentVNode("", true), _ctx.code === 20016 ? (_openBlock(), _createBlock(_component_Remark, {
    key: 2,
    title: "配额超限页评论",
    type: 8
  })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}