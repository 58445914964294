import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
export default defineComponent({
  props: {
    // 显示弹窗
    show: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: String,
      default: ""
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var time = ref(0);
    var loading = ref(false);

    function init() {
      if (props.config.agree_time_check) {
        time.value = props.config.agree_time;

        if (time.value > 0) {
          var timer = setInterval(function () {
            time.value -= 1;

            if (time.value === 0) {
              clearInterval(timer);
            }
          }, 1000);
        }
      }
    }

    init(); // 同意

    function agree() {
      if (time.value === 0) {
        loading.value = true;
        context.emit("update:answer", {
          value: "1"
        });
        context.emit("next");
        loading.value = false;
      } else {
        message.error("\u8FD8\u9700\u9605\u8BFB".concat(time.value, "\u79D2"));
      }
    } // 拒绝


    function refuse() {
      if (!loading.value) {
        loading.value = true;
        context.emit("update:answer", {
          value: "2"
        });
        context.emit("next");
        loading.value = false;
      }
    }

    return {
      time: time,
      loading: loading,
      agree: agree,
      refuse: refuse
    };
  }
});