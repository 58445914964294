import _defineProperty from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.find.js";
import { defineComponent, ref, watch } from "vue";
import RichRateItem from "../../planetDesign/Design/components/RichRateItem.vue";
import SliderNumRate from "../../planetDesign/Design/components/SliderNumRate.vue";
export default defineComponent({
  components: {
    RichRateItem: RichRateItem,
    SliderNumRate: SliderNumRate
  },
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 选项
    var options = ref([]); // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });

      if (props.answer) {
        options.value.forEach(function (option) {
          option.value = props.answer[option.option_key];
        });
      }
    }

    init(); // 打分回调(更新答案)

    function updateAnswer() {
      var index = options.value.findIndex(function (option) {
        return !option.value;
      });

      if (index === -1) {
        var answer = {};
        options.value.forEach(function (option) {
          answer = _objectSpread(_objectSpread({}, answer), _defineProperty({}, option.option_key, option.value));
        });
        context.emit("update:answer", answer);
      } else {
        context.emit("update:answer", null);
      }
    } // 打分组件颜色


    function getColor() {
      var color = "#FEC740";

      switch (props.config.score_type) {
        case 1:
          color = "#1C6FFF";
          break;

        case 2:
          color = "#FA7070";
          break;

        case 3:
          color = "#FEC740";
          break;

        case 4:
          color = "#1C6FFF";
          break;

        case 5:
          color = "#1C6FFF";
          break;

        default:
          break;
      }

      return color;
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      if (options.value.length > 1) context.emit("changeAnswer", {
        options: options.value
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      // 更新关联题选项
      var newOptions = [];
      props.list.forEach(function (list) {
        newOptions = [].concat(_toConsumableArray(newOptions), _toConsumableArray(list.options));
      });

      if (props.answer) {
        newOptions.forEach(function (newOption) {
          if (!newOption.value) newOption.value = props.answer[newOption.option_key];
        });
      } else {
        newOptions.forEach(function (newOption) {
          var oldOption = options.value.find(function (oldOption) {
            return oldOption.option_key === newOption.option_key;
          });
          if (!newOption.value) newOption.value = oldOption === null || oldOption === void 0 ? void 0 : oldOption.value;
        });
      }

      options.value = newOptions; // 更新答案

      updateAnswer();
    }, {
      deep: true
    });
    return {
      options: options,
      updateAnswer: updateAnswer,
      getColor: getColor
    };
  }
});