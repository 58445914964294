import "core-js/modules/es.number.constructor.js";
import { defineComponent } from "vue";
import PfeButton from "./PfeButton.vue";
export default defineComponent({
  components: {
    PfeButton: PfeButton
  },
  props: {
    // 当前页数
    page: {
      type: Number,
      default: 1
    },
    // 总页数
    pages: {
      type: Number,
      default: 1
    },
    // 最小页数
    min: {
      type: Number,
      default: 1
    },
    // 显示上一页按钮
    showPrevious: {
      type: [Boolean, Number],
      default: false
    },
    // 显示开始按钮
    showStart: {
      type: [Boolean, Number],
      default: false
    },
    // 开始按钮文字
    startText: {
      type: String,
      default: "开始"
    },
    // 显示提交按钮
    showSubmit: {
      type: [Boolean, Number],
      default: false
    },
    // 提交按钮文字
    submitText: {
      type: String,
      default: "提交"
    },
    // 按钮背景颜色
    buttonColor: {
      type: String,
      default: ""
    },
    // 按钮文字颜色
    buttonTextColor: {
      type: String,
      default: ""
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 上一页
    function previous() {
      if (props.page > props.min) {
        context.emit("previous");
        context.emit("update:page", props.page - 1);
      }
    } // 下一页


    function next() {
      if (props.page < props.pages) {
        context.emit("next");
        context.emit("update:page", props.page + 1);
      }
    }

    return {
      previous: previous,
      next: next
    };
  }
});