import { defineComponent, ref, computed } from "vue";
import { Textarea, Button, Divider } from "ant-design-vue";
export default defineComponent({
  props: ['content'],
  emits: ['update:content', 'cancel:content'],
  components: {
    Textarea: Textarea,
    Button: Button,
    Divider: Divider
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var text = computed({
      get: function get() {
        return props.content;
      },
      set: function set(newValue) {
        emit('update:content', newValue);
      }
    });
    /**
     * 清空内容
     */

    function handleCancel() {
      text.value = '';
      emit('cancel:content');
    }
    /**
     * 提交内容
     */


    function handleCommit() {
      emit('commit:message', text.value);
    }

    return {
      text: text,
      handleCancel: handleCancel,
      handleCommit: handleCommit
    };
  }
});