import "core-js/modules/es.array.concat.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0ea127c6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "question"
};
var _hoisted_2 = {
  key: 0,
  class: "question-inner-wrapper"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = {
  class: "stem"
};
var _hoisted_5 = {
  key: 1,
  class: "error"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_rich_text = _resolveComponent("rich-text");

  var _component_Remark = _resolveComponent("Remark");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.questionType <= 100 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: "title",
    style: _normalizeStyle("color: ".concat(_ctx.themeColor.stemColor))
  }, [_ctx.showTitle ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "question-inner-span",
    innerHTML: _ctx.title
  }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [_createVNode(_component_rich_text, {
    nodes: "".concat(_ctx.newTitle).concat(_ctx.tip),
    isPreview: ""
  }, null, 8, ["nodes"])])], 4), !_ctx.isAnswer ? (_openBlock(), _createBlock(_component_Remark, {
    key: 0,
    title: _ctx.title + '问题评论',
    type: 3,
    questionIndex: _ctx.questionIndex
  }, null, 8, ["title", "questionIndex"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _ctx.error && _ctx.questionType <= 100 ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}