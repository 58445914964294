import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find-index.js";
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 隐藏选项
    hideOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var value = ref([]); // 值

    var options = ref([]); // 选项
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });
      sortOptions();

      if (props.answer) {
        value.value = Object.keys(props.answer).map(function (key) {
          return key;
        });
        options.value.forEach(function (option) {
          if (option.is_other) {
            var _props$answer;

            option.value = (_props$answer = props.answer) === null || _props$answer === void 0 ? void 0 : _props$answer[option.option_key];
          }
        });
      }
    }

    init(); // 排序(其他项,排他项不参与随机)

    function sortOptions() {
      if (props.config.select_random) {
        var common = [];
        var others = [];
        var removeOther = [];
        var otherRemoveOther = [];
        options.value.forEach(function (option) {
          if (option.is_other && option.is_remove_other) {
            otherRemoveOther.push(option);
          } else if (option.is_remove_other) {
            removeOther.push(option);
          } else if (option.is_other) {
            others.push(option);
          } else {
            common.push(option);
          }
        });
        options.value = [].concat(common, others, removeOther, otherRemoveOther);
      }
    } // 选择回调


    function changeValue(e) {
      // 排他项
      if (e.length) {
        var last = e[e.length - 1];
        var option = options.value.find(function (option) {
          return option.option_key === last;
        });

        if (option.is_remove_other) {
          e = [last];
        } else {
          e = e.filter(function (val) {
            return options.value.find(function (option) {
              return option.option_key === val && !option.is_remove_other;
            });
          });
        }
      }

      if (props.config.max_select === "" || e.length <= props.config.max_select) {
        // 更新值
        value.value = e; // 更新答案

        if (e.length) {
          var answer = {};
          e.forEach(function (val) {
            var option = options.value.find(function (option) {
              return option.option_key === val;
            });
            answer[val] = option.value || (option.is_other ? "" : '1');
          });
          context.emit("update:answer", answer);
        } else {
          context.emit("update:answer", null);
        }
      } // 清空未选中项输入框值


      options.value.forEach(function (option) {
        if (option.is_other && !e.includes(option.option_key)) {
          option.value = "";
        }
      });
    } // 输入回调


    function changeInput(e, key) {
      var index = value.value.findIndex(function (val) {
        return val === key;
      });
      changeValue(index === -1 ? [].concat(_toConsumableArray(value.value), [key]) : value.value);
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        options: options.value,
        value: value.value
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      // 更新关联题选项
      var newOptions = [];
      props.list.forEach(function (list) {
        newOptions = [].concat(_toConsumableArray(newOptions), _toConsumableArray(list.options));
      }); // 其他项

      newOptions.forEach(function (option) {
        if (option.is_other && value.value.includes(option.option_key)) {
          var timer = setTimeout(function () {
            option.value = props.answer[option.option_key];
            clearTimeout(timer);
          });
        }
      });
      options.value = newOptions;
      sortOptions(); // 清空值和答案

      if (value.value.findIndex(function (val) {
        return !options.value.map(function (option) {
          return option.option_key;
        }).includes(val);
      }) !== -1) {
        // 清空值
        value.value = []; // 清空答案

        context.emit("update:answer", null);
      }
    }, {
      deep: true
    });
    return {
      value: value,
      options: options,
      changeValue: changeValue,
      changeInput: changeInput
    };
  }
});