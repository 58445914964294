import _objectSpread from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import { defineComponent, ref } from "vue";
import MatrixRadio from "../QMatrix/MatrixRadio.vue";
import Remark from "@/views/Answer/components/Remark";
export default defineComponent({
  components: {
    MatrixRadio: MatrixRadio,
    Remark: Remark
  },
  props: {
    // 错误
    error: {
      type: String,
      default: ""
    },
    // 标题
    title: {
      type: String,
      default: ""
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否作答模式
    isAnswer: {
      type: Boolean
    },
    // 题号
    questionIndex: {
      type: [String, Number],
      default: ""
    },
    // 题干
    label: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props, context) {
    var list = ref([]); // 矩阵列表

    var questions = ref([]); // 问题列表
    // 初始化

    function init() {
      questions.value = props.config.cell_options.map(function (question) {
        return {
          title: question,
          stem: props.title.replace("[%Feature%]", question),
          list: setList(question)
        };
      });

      if (props.answer) {
        questions.value.forEach(function (question, index) {
          question.answer = props.answer[index];
        });
      }
    }

    init(); // 选择回调

    function changeValue() {
      // 更新答案
      var answer = questions.value.map(function (question, task) {
        return question.answer ? _objectSpread(_objectSpread({}, question.answer), {
          task: task + 1
        }) : null;
      });
      var index = answer.findIndex(function (value) {
        return !value;
      });

      if (index === -1) {
        context.emit("update:answer", answer);
      }
    } // 设置list


    function setList(title) {
      return [{
        type: 1,
        //行标签
        options: [{
          option: "\u5177\u5907\u201C".concat(title, "\u201D"),
          option_key: "1"
        }, {
          option: "\u4E0D\u5177\u5907\u201C".concat(title, "\u201D"),
          option_key: "2"
        }]
      }, {
        type: 2,
        // 列标签
        options: [{
          option: "不喜欢",
          option_key: "1"
        }, {
          option: "能忍受",
          option_key: "2"
        }, {
          option: "无所谓",
          option_key: "3"
        }, {
          option: "理应如此",
          option_key: "4"
        }, {
          option: "喜欢",
          option_key: "5"
        }]
      }];
    }

    return {
      list: list,
      questions: questions,
      changeValue: changeValue
    };
  }
});