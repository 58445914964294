import _objectSpread from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import { defineComponent, ref, watch } from "vue";
import draggable from "vuedraggable";
export default defineComponent({
  components: {
    draggable: draggable
  },
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 组名
    var groupName = "class_".concat(new Date().getTime(), "_").concat(Math.floor(Math.random() * 1000)); // 选项

    var options = ref([]);
    var sort = ref([]); // 初始化

    function init() {
      props.list.forEach(function (list) {
        if (list.type === 0) {
          options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
        } else if (list.type === 3) {
          sort.value = [].concat(_toConsumableArray(sort.value), _toConsumableArray(list.options.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              value: []
            });
          })));
        }
      });

      if (props.answer) {
        Object.keys(props.answer).forEach(function (key) {
          var sortItem = sort.value.find(function (item) {
            return item.option_key === props.answer[key];
          });
          var index = options.value.findIndex(function (option) {
            if (option.option_key === key) {
              sortItem.value.push(option);
            }

            return option.option_key === key;
          });
          options.value.splice(index, 1);
        });
        updateAnswer();
      }
    }

    init(); // 更新答案

    function updateAnswer() {
      if (options.value.length) {
        context.emit("update:answer", null);
      } else {
        var answer = {};
        sort.value.forEach(function (item) {
          item.value.forEach(function (option) {
            answer[option.option_key] = item.option_key;
          });
        });
        context.emit("update:answer", answer);
      }
    } // 监听拖动事件


    watch([options, sort], function () {
      updateAnswer();
    }, {
      deep: true // 开启深度监视,

    });
    return {
      groupName: groupName,
      options: options,
      sort: sort
    };
  }
});