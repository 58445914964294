import { createApp, defineComponent} from "vue";
import router from "@router";
import store from "@store";
import CommentConstructor from "@/views/Answer/components/Remark/RemarkDrawer";


let modalApp = null
function openModal(options = {}, context = null) {
    if(modalApp) {
      modalApp.unmount() && document.body.removeChild(document.getElementById('comment-modal'))
    }
    modalApp = createApp(CommentConstructor, {
      modelValue: true,
      visible: true,
      title: options.title || "title", //标题
      sn: options.sn || "", //问卷sn
      questionIndex: options.questionIndex || "", //题号
      type: options.type || "",  // 题型
      close: () => {
        console.log("close");
      },
    });
    const dom = document.createElement('div')
    dom.id = 'comment-modal'
    document.body.appendChild(dom);
    modalApp.use(router).use(store).mount(dom);
}

export default openModal;
