import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 选项
    var row = ref([]); // 行标签

    var col = ref([]); // 列标签
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        if (list.type === 1) {
          row.value = [].concat(_toConsumableArray(row.value), _toConsumableArray(list.options));
        } else if (list.type === 2) {
          col.value = [].concat(_toConsumableArray(col.value), _toConsumableArray(list.options));
        }
      });

      if (props.answer) {
        var obj = {};
        Object.keys(props.answer).forEach(function (key) {
          obj[key.split("_")[0]] = key.split("_")[1];
        });
        row.value.forEach(function (rowItem) {
          rowItem.value = obj[rowItem.option_key];
        });
        updateAnswer();
      }
    }

    init(); // 选择回调

    function updateAnswer() {
      var _props$config;

      var answer = {};
      var index = row.value.findIndex(function (rowItem) {
        if (rowItem.value) {
          answer["".concat(rowItem.option_key, "_").concat(rowItem.value)] = 1;
        }

        return !rowItem.value;
      }); // 更新答案

      if (index === -1 || !((_props$config = props.config) !== null && _props$config !== void 0 && _props$config.is_required)) {
        context.emit("update:answer", answer);
      } else {
        context.emit("update:answer", null);
      }
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        list: props.list
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      // 更新关联题选项
      var newRow = [];
      var newCol = [];
      props.list.forEach(function (list) {
        if (list.type === 1) {
          newRow = [].concat(_toConsumableArray(newRow), _toConsumableArray(list.options));
        } else if (list.type === 2) {
          newCol = [].concat(_toConsumableArray(newCol), _toConsumableArray(list.options));
        }
      });
      var colKeys = newCol.map(function (colItem) {
        return colItem.option_key;
      });
      newRow.forEach(function (rowItem) {
        var index = colKeys.findIndex(function (colKey) {
          return colKey === rowItem.value;
        });

        if (index === -1) {
          rowItem.value = "";
        }
      });
      row.value = newRow;
      col.value = newCol; // 清空值和答案

      updateAnswer();
    }, {
      deep: true
    }); // 是否禁用选项

    function isDisabled(rowKey, colKey) {
      var _props$config2;

      var index = (((_props$config2 = props.config) === null || _props$config2 === void 0 ? void 0 : _props$config2.disabled) || []).findIndex(function (item) {
        return item.row_key === rowKey && item.cell_key === colKey;
      });
      return index === -1 ? false : true;
    }

    return {
      row: row,
      col: col,
      updateAnswer: updateAnswer,
      isDisabled: isDisabled
    };
  }
});