import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    // 富文本内容
    nodes: {
      type: String,
      default: ""
    },
    // 是否开启预览图片功能
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var visible = ref(false);
    var previewImg = ref(""); // 点击富文本的事件

    function handleRich(e) {
      if (props.isPreview && e.target.tagName == "IMG") {
        previewImg.value = e.target.src;
        setVisible(true);
      }
    } // 预览图片


    function setVisible(value) {
      visible.value = value;
    }

    return {
      visible: visible,
      previewImg: previewImg,
      handleRich: handleRich,
      setVisible: setVisible
    };
  }
});