import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-73b800fe"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "remark-section"
};
var _hoisted_2 = {
  key: 0,
  class: "icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.isPreview ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("i", {
    class: "iconfont icon-pinglun",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.openDrawer && _ctx.openDrawer.apply(_ctx, arguments);
    })
  })])) : _createCommentVNode("", true)]);
}