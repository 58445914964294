import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
import { defineComponent, ref } from "vue";
import cityes from "./json/cityes.json";
import university from "./json/university.json";
import major from "./json/major.json"; // 递归

var recursive = function recursive(arr) {
  var newArr = [{
    value: null,
    options: arr
  }];

  function recursive(options) {
    var option = options.find(function (option) {
      return option.children;
    });

    if (option) {
      newArr.push({
        value: null
      });
      recursive(option.children);
    }
  }

  recursive(arr);
  return newArr;
};
/**
 * @param level 1：省 2：省市 3：省市区/县
 */


var getCityes = function getCityes(level) {
  // 深拷贝
  var cityesStr = JSON.stringify(cityes);
  var newCityes = JSON.parse(cityesStr);

  switch (level) {
    case 1:
      newCityes.forEach(function (province) {
        delete province.children;
      });
      break;

    case 2:
      newCityes.forEach(function (province) {
        var _province$children;

        (_province$children = province.children) === null || _province$children === void 0 ? void 0 : _province$children.forEach(function (city) {
          delete city.children;
        });
      });
      break;

    default:
      break;
  }

  return newCityes;
};

export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var options = ref([]); // 选项

    var optionsLevel = ref([]); // 每级选项
    // 初始化

    function init() {
      options.value = props.list[0].options;

      if ([3, 4, 5].includes(props.config.quick_type)) {
        options.value = getCityes(props.config.quick_type - 2);
      } else if (props.config.quick_type === 13) {
        options.value = university;
      } else if (props.config.quick_type === 14) {
        options.value = major;
      }

      optionsLevel.value = recursive(options.value);

      if (props.answer) {
        Object.keys(props.answer).forEach(function (key, index) {
          Object.keys(props.answer).forEach(function (value) {
            if (props.answer[value] - 1 === index) {
              optionsLevel.value[index].value = value;
              changeValue(value, index);
            }
          });
        });
      }
    }

    init(); // 选择回调

    function changeValue(value, index) {
      var nextIndex = index + 1;

      if (nextIndex < optionsLevel.value.length) {
        var _optionsLevel$value$i;

        optionsLevel.value[nextIndex].options = (_optionsLevel$value$i = optionsLevel.value[index].options.find(function (option) {
          return option.option_key === value;
        })) === null || _optionsLevel$value$i === void 0 ? void 0 : _optionsLevel$value$i.children;
      }

      optionsLevel.value.forEach(function (val, i) {
        if (i > index) {
          optionsLevel.value[i].value = null;
        }

        if (i > nextIndex) {
          optionsLevel.value[i].options = [];
        }
      }); // 更新答案

      if (optionsLevel.value[optionsLevel.value.length - 1].value) {
        var answer = {};
        optionsLevel.value.forEach(function (level, index) {
          if (level.value) {
            answer[level.value] = index + 1;
          }
        });
        context.emit("update:answer", answer);
      } else {
        context.emit("update:answer", null);
      }
    } // 阿拉伯数字转中文


    function toChinese(num) {
      switch (num) {
        case 1:
          return "一";

        case 2:
          return "二";

        case 3:
          return "三";

        case 4:
          return "四";

        default:
          return num;
      }
    }

    return {
      options: options,
      optionsLevel: optionsLevel,
      changeValue: changeValue,
      toChinese: toChinese
    };
  }
});