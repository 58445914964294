import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import { defineComponent, ref, watch } from "vue";
import { qqmapKey } from "@/utils/mapKey";
export default defineComponent({
  props: {
    // 题号
    questionIndex: {
      type: Number,
      default: 0
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var keyword = ref("");
    var location = ref(null);
    var loading = ref(false);
    var map = null;
    var markerLayer = null;
    var geolocation = null;
    var referer = "planetg";
    var options = {
      timeout: 3000
    };
    var isGps = true; // 是否精准定位
    // 初始化

    function init() {
      geolocation = new qq.maps.Geolocation(qqmapKey, referer);

      if (props.answer) {
        location.value = props.answer;
        var center = new TMap.LatLng(props.answer.lat, props.answer.lng);
        var timer = setTimeout(function () {
          map = new TMap.Map(document.getElementById("container".concat(props.questionIndex)), {
            center: center,
            zoom: 16
          });
          markerLayer = new TMap.MultiMarker({
            map: map,
            styles: {
              marker: new TMap.MarkerStyle({
                width: 25,
                height: 35
              })
            },
            geometries: [{
              id: "marker",
              styleId: "marker",
              position: center
            }]
          });
          clearTimeout(timer);
        });
      }
    }

    init(); // 监听地理位置

    watch([location], function () {
      var _location$value;

      // 更新答案
      if ((_location$value = location.value) !== null && _location$value !== void 0 && _location$value.lat) {
        context.emit("update:answer", location.value);
      } else {
        context.emit("update:answer", null);
      }
    }, {
      deep: true // 开启深度监视,

    }); // 获取地理位置

    function getLocation() {
      keyword.value = "";
      loading.value = true; // 初始化地图

      if (!location.value) {
        location.value = {};
        var timer = setTimeout(function () {
          // 定义map变量，调用 TMap.Map() 构造函数创建地图
          if (!map) {
            map = new TMap.Map(document.getElementById("container".concat(props.questionIndex)), {
              zoom: 16
            });
          }

          clearTimeout(timer);
        });
      }

      if (isGps) {
        // 获取精确定位信息
        geolocation.getLocation(showPosition, showErr, options);
      } else {
        // 获取粗糙定位信息
        geolocation.getIpLocation(showPosition, showErr);
      }
    } // 定位成功


    function showPosition(position) {
      loading.value = false;
      location.value = {
        lat: position.lat,
        lng: position.lng,
        position: "".concat(position.province).concat(position.city).concat(position.addr)
      }; // 定义地图中心点坐标

      var center = new TMap.LatLng(position.lat, position.lng); //设置地图中心点坐标

      map.setCenter(center); // 初始化marker

      markerLayer = new TMap.MultiMarker({
        map: map,
        // 点标注的相关样式
        styles: {
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 35
          })
        },
        // 点标注数据数组
        geometries: [{
          id: "marker",
          // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          styleId: "marker",
          position: center
        }]
      });
    } // 定位失败


    function showErr(error) {
      isGps = false;
      getLocation();
    } // 回车事件


    function pressEnter(_ref) {
      var keyCode = _ref.keyCode;
      if (keyCode === 13) searchLocation();
    } // 搜索定位


    function searchLocation() {
      if (keyword.value) {
        // 地址解析
        var geocoder = new qq.maps.Geocoder({
          complete: function complete(result) {
            var _result$detail$locati = result.detail.location,
                lat = _result$detail$locati.lat,
                lng = _result$detail$locati.lng;
            location.value = {
              lat: lat,
              lng: lng,
              position: result.detail.address
            };
            var center = new TMap.LatLng(lat, lng);
            map.setCenter(center);
            markerLayer.updateGeometries([{
              id: "marker",
              styleId: "marker",
              position: center
            }]);
          }
        });
        geocoder.getLocation(keyword.value);
      }
    }

    return {
      keyword: keyword,
      location: location,
      loading: loading,
      getLocation: getLocation,
      pressEnter: pressEnter,
      searchLocation: searchLocation
    };
  }
});