import _defineProperty from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import RichText from "@/components/RichText.vue";
import { defineComponent, inject, ref, watch } from "vue";
export default defineComponent({
  components: {
    RichText: RichText
  },
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 隐藏选项
    hideOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var value = ref(""); // 值

    var options = ref([]); // 选项
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });
      sortOptions();

      if (props.answer) {
        value.value = Object.keys(props.answer)[0];
        options.value.forEach(function (option) {
          if (option.is_other && option.option_key === value.value) {
            option.value = props.answer[value.value];
          }
        });
      }
    }

    init(); // 排序(其他项不参与随机)

    function sortOptions() {
      if (props.config.select_random) {
        var common = [];
        var others = [];
        options.value.forEach(function (option) {
          if (option.is_other) {
            others.push(option);
          } else {
            common.push(option);
          }
        });
        options.value = common.concat(others);
      }
    } // 选择回调


    function changeValue(e) {
      // 更新答案
      var option = options.value.find(function (option) {
        return option.option_key === e.target.value;
      });
      context.emit("update:answer", _defineProperty({}, e.target.value, option.value || (option.is_other ? "" : "1"))); // 清空未选中项输入框值

      options.value.forEach(function (option) {
        if (option.is_other && option.option_key !== e.target.value) {
          option.value = "";
        }
      });
    } // 输入回调


    function changeInput(e, key) {
      value.value = key; // 更新答案

      context.emit("update:answer", _defineProperty({}, key, e.target.value));
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        options: options.value,
        value: value.value
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      // 更新关联题选项
      var newOptions = [];
      props.list.forEach(function (list) {
        newOptions = [].concat(_toConsumableArray(newOptions), _toConsumableArray(list.options));
      }); // 其他项

      newOptions.forEach(function (option) {
        if (option.is_other && option.option_key === value.value) {
          var timer = setTimeout(function () {
            option.value = props.answer[value.value];
            clearTimeout(timer);
          });
        }
      });
      options.value = newOptions;
      sortOptions(); // 清空值和答案

      if (value.value && options.value.findIndex(function (option) {
        return option.option_key === value.value;
      }) === -1) {
        // 清空值
        value.value = ""; // 清空答案

        context.emit("update:answer", null);
      }
    }, {
      deep: true
    });
    return {
      value: value,
      options: options,
      changeValue: changeValue,
      changeInput: changeInput
    };
  }
});