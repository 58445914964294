import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0d110606"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_image = _resolveComponent("a-image");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleRich && _ctx.handleRich.apply(_ctx, arguments);
    }),
    innerHTML: _ctx.nodes
  }, null, 8, _hoisted_1), _createVNode(_component_a_image, {
    src: _ctx.previewImg,
    style: {
      display: 'none'
    },
    preview: {
      visible: _ctx.visible,
      onVisibleChange: _ctx.setVisible
    }
  }, null, 8, ["src", "preview"])]);
}