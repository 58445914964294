import "core-js/modules/es.array.sort.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2bfb0552"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "q-sort"
};
var _hoisted_2 = {
  class: "sort-box answer-border"
};
var _hoisted_3 = {
  class: "d-title answer-color answer-background30"
};
var _hoisted_4 = {
  class: "d-box scrollbar"
};
var _hoisted_5 = {
  class: "option answer-color"
};
var _hoisted_6 = ["innerHTML"];
var _hoisted_7 = {
  class: "sort-box right-box answer-border"
};
var _hoisted_8 = {
  class: "d-title answer-color answer-background30"
};
var _hoisted_9 = {
  class: "d-box scrollbar right"
};
var _hoisted_10 = {
  class: "option answer-color"
};
var _hoisted_11 = {
  key: 0
};
var _hoisted_12 = ["innerHTML"];
var _hoisted_13 = {
  class: "d-title answer-color answer-background30"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_draggable = _resolveComponent("draggable");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.config.initial_label), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_draggable, {
    modelValue: _ctx.options,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.options = $event;
    }),
    group: _ctx.groupName,
    "item-key": "id",
    style: {
      "height": "100%"
    },
    animation: 300,
    move: _ctx.onMove,
    disabled: _ctx.disabled
  }, {
    item: _withCtx(function (_ref) {
      var element = _ref.element;
      return [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: _normalizeClass(["scrollbar answer-background30", _ctx.config.sort_scope && _ctx.sort.length === _ctx.config.sort_scope ? 'disabled' : '']),
        innerHTML: element.option
      }, null, 10, _hoisted_6)])];
    }),
    _: 1
  }, 8, ["modelValue", "group", "move", "disabled"])])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.config.top_label || "↑"), 1), _createElementVNode("div", _hoisted_9, [_createVNode(_component_draggable, {
    modelValue: _ctx.sort,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.sort = $event;
    }),
    group: _ctx.groupName,
    "item-key": "id",
    style: {
      "height": "100%"
    },
    animation: 300,
    disabled: _ctx.disabled
  }, {
    item: _withCtx(function (_ref2) {
      var element = _ref2.element,
          index = _ref2.index;
      return [_createElementVNode("div", _hoisted_10, [_ctx.config.show_index ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(index + 1), 1)) : _createCommentVNode("", true), _createElementVNode("div", {
        class: "scrollbar answer-background30",
        innerHTML: element.option
      }, null, 8, _hoisted_12)])];
    }),
    _: 1
  }, 8, ["modelValue", "group", "disabled"])]), _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.config.bottom_label || "↓"), 1)])]);
}