import "core-js/modules/es.number.constructor.js";
import { defineComponent, ref } from "vue";
import RemarkEditor from "@/views/Answer/components/Remark/RemarkEditor";
import avatar from "@/views/Answer/components/Remark/avatar";
export default defineComponent({
  props: {
    info: {
      type: Object,
      default: function _default() {}
    },
    userId: {
      type: Number,
      default: undefined
    },
    isAllComment: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RemarkEditor: RemarkEditor,
    avatar: avatar
  },
  emits: ["addReply", "delReply"],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var content = ref("");
    var isRenderEditor = ref(false);
    /**
     * 打开编辑器
     */

    function OpenReplyEditor() {
      isRenderEditor.value = true;
    }
    /**
     * 关闭编辑器 清空内容
     */


    function handleCancel() {
      content.value = '';
      isRenderEditor.value = false;
    }
    /**
     * 发送回复内容
     */


    function sendReplyMessage() {
      emit("addReply", {
        comment_id: props.info.comment_id,
        content: content.value,
        type: props.isAllComment ? props.info.type : null,
        question_index: props.isAllComment ? props.info.question_index : null
      });
      handleCancel();
    }
    /**
     * 删除回复
     */


    function deleteReply(comment_id) {
      emit("delReply", comment_id);
    }

    return {
      content: content,
      isRenderEditor: isRenderEditor,
      OpenReplyEditor: OpenReplyEditor,
      handleCancel: handleCancel,
      sendReplyMessage: sendReplyMessage,
      deleteReply: deleteReply
    };
  }
});