import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1e49bd54"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "option"
};
var _hoisted_4 = {
  key: 0,
  class: "label left answer-color"
};
var _hoisted_5 = {
  key: 1,
  class: "label right answer-color"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_input = _resolveComponent("a-input");

  var _component_a_input_number = _resolveComponent("a-input-number");

  return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option) {
    return _openBlock(), _createElementBlock("div", {
      class: "option",
      key: option.option_key
    }, [_ctx.config.location === 1 ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      class: "label left answer-color",
      innerHTML: option.option
    }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _createVNode(_component_a_input, {
      type: "number",
      value: option.value,
      disabled: _ctx.disabled,
      onChange: function onChange($event) {
        return _ctx.updateAnswer($event, option);
      }
    }, null, 8, ["value", "disabled", "onChange"]), _ctx.config.location === 0 ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      class: "label right answer-color",
      innerHTML: option.option
    }, null, 8, _hoisted_2)) : _createCommentVNode("", true)]);
  }), 128)), _createElementVNode("div", _hoisted_3, [_ctx.config.location === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.config.show_label), 1)) : _createCommentVNode("", true), _createVNode(_component_a_input_number, {
    type: "number",
    value: _ctx.sum,
    disabled: ""
  }, null, 8, ["value"]), _ctx.config.location === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.config.show_label), 1)) : _createCommentVNode("", true)])]);
}