import "core-js/modules/es.array.includes.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e7295ae6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_input = _resolveComponent("a-input");

  var _component_a_textarea = _resolveComponent("a-textarea");

  return [5, 6, 7].includes(_ctx.config.text_type) ? (_openBlock(), _createBlock(_component_a_input, {
    key: 0,
    value: _ctx.value,
    placeholder: _ctx.config.placeholder,
    addonAfter: _ctx.config.right_prompt,
    addonBefore: _ctx.config.left_prompt,
    onChange: _ctx.changeValue,
    disabled: _ctx.disabled
  }, null, 8, ["value", "placeholder", "addonAfter", "addonBefore", "onChange", "disabled"])) : _ctx.config.text_type === 1 || _ctx.config.text_type === 2 ? (_openBlock(), _createBlock(_component_a_input, {
    key: 1,
    type: "number",
    value: _ctx.value,
    placeholder: _ctx.config.placeholder,
    addonAfter: _ctx.config.right_prompt,
    addonBefore: _ctx.config.left_prompt,
    step: _ctx.config.text_type === 1 ? 1 : 1 / Math.pow(10, _ctx.config.decimal_few || 0),
    onChange: _ctx.changeValue,
    onBlur: _ctx.imposeNum,
    disabled: _ctx.disabled
  }, null, 8, ["value", "placeholder", "addonAfter", "addonBefore", "step", "onChange", "onBlur", "disabled"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.config.line_type ? (_openBlock(), _createBlock(_component_a_textarea, {
    key: 0,
    value: _ctx.value,
    placeholder: _ctx.config.placeholder,
    addonAfter: _ctx.config.right_prompt,
    addonBefore: _ctx.config.left_prompt,
    onChange: _ctx.changeValue,
    disabled: _ctx.disabled,
    "auto-size": {
      minRows: _ctx.config.line_height,
      maxRows: _ctx.config.line_height
    }
  }, null, 8, ["value", "placeholder", "addonAfter", "addonBefore", "onChange", "disabled", "auto-size"])) : (_openBlock(), _createBlock(_component_a_input, {
    key: 1,
    value: _ctx.value,
    placeholder: _ctx.config.placeholder,
    addonAfter: _ctx.config.right_prompt,
    addonBefore: _ctx.config.left_prompt,
    onChange: _ctx.changeValue,
    disabled: _ctx.disabled
  }, null, 8, ["value", "placeholder", "addonAfter", "addonBefore", "onChange", "disabled"]))]));
}