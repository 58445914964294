import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0344060f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "radio-group"
};
var _hoisted_2 = {
  class: "radio-option"
};
var _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_image = _resolveComponent("a-image");

  var _component_a_radio = _resolveComponent("a-radio");

  var _component_a_radio_group = _resolveComponent("a-radio-group");

  return _openBlock(), _createBlock(_component_a_radio_group, {
    value: _ctx.value,
    "onUpdate:value": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.value = $event;
    }),
    onChange: _ctx.changeValue,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option) {
        return _openBlock(), _createElementBlock("div", {
          class: "radio",
          key: option.option_key,
          style: _normalizeStyle(_ctx.isMobile ? 'width:100%' : "width: calc(100% / ".concat(_ctx.config.each_number, ")"))
        }, [_createVNode(_component_a_image, {
          width: "100%",
          src: option.option_config.image_url[0]
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_a_radio, {
          value: option.option_key
        }, null, 8, ["value"]), _createElementVNode("div", {
          innerHTML: option.option
        }, null, 8, _hoisted_3)])], 4);
      }), 128))])];
    }),
    _: 1
  }, 8, ["value", "onChange", "disabled"]);
}