import request from "@/utils/request";
export default class AnswerApi {
  /* 获取问卷 */
  static getQuetions(params) {
    return request({
      method: "GET",
      url: `/answer/surveys/${params.id}`,
      params: params.data,
    });
  }

  /* 获取问卷详情 */
  static getQuetionDetail(params) {
    return request({
      method: "GET",
      url: `/console/surveys/${params.id}/detail`,
      params: params.data,
    });
  }

  /* 答题 */
  static answer(params) {
    return request({
      method: "POST",
      url: `/answer/surveys/${params.id}`,
      data: params.data,
    });
  }

  /* 获取验证码 */
  static getCode(params) {
    return request({
      method: "POST",
      url: `/system/sms_codes`,
      data: params.data,
    });
  }

  /* 问卷密码 */
  static password(params) {
    return request({
      method: "PATCH",
      url: `/answer/surveys/${params.id}/password`,
      data: params.data,
    });
  }


  /* word导出 */

  static download(sn, params='') {
    return request({
      method: 'get',
      url: `/console/survey_word_export/${sn}?${params}`,
    })
  }
}

