import "core-js/modules/es.number.constructor.js";
import { defineComponent } from "vue";
import RichRateItem from "../../../../planetDesign/Design/components/RichRateItem.vue";
import SliderNumRate from "../../../../planetDesign/Design/components/SliderNumRate.vue";
export default defineComponent({
  components: {
    RichRateItem: RichRateItem,
    SliderNumRate: SliderNumRate
  },
  props: {
    // 值
    value: {
      type: Number,
      default: 0
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // 打分回调
    function changeValue(value) {
      if (props.disabled) return;
      context.emit("update:value", value);
      context.emit("change", value);
    } // 打分组件颜色


    function getColor() {
      var color = "#FEC740";

      switch (props.config.score_type) {
        case 1:
          color = "#1C6FFF";
          break;

        case 2:
          color = "#FA7070";
          break;

        case 3:
          color = "#FEC740";
          break;

        case 4:
          color = "#1C6FFF";
          break;

        case 5:
          color = "#1C6FFF";
          break;

        default:
          break;
      }

      return color;
    }

    return {
      changeValue: changeValue,
      getColor: getColor
    };
  }
});