import _toConsumableArray from "/root/workspace/survey-java-web_GWKp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    // 列表
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置
    config: {
      type: Object,
      default: function _default() {}
    },
    // 答案
    answer: {
      type: Object,
      default: function _default() {}
    },
    // 隐藏选项
    hideOptions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var options = ref([]); // 选项
    // 初始化

    function init() {
      props.list.forEach(function (list) {
        options.value = [].concat(_toConsumableArray(options.value), _toConsumableArray(list.options));
      });
      options.value.forEach(function (option) {
        var _props$answer;

        option.status = ((_props$answer = props.answer) === null || _props$answer === void 0 ? void 0 : _props$answer[option.option_key]) || 0;
      });

      if (props.answer) {
        updateAnswer();
      }
    }

    init(); // 点击热区

    function handleArea(option) {
      if (props.disabled) return;

      if (option.status === 2) {
        option.status = 0;
      } else {
        var likeLength = 0;
        var unlikeLength = 0;
        options.value.forEach(function (option) {
          if (option.status === 1) {
            likeLength += 1;
          } else if (option.status === 2) {
            unlikeLength += 1;
          }
        });

        if (likeLength === props.config.max_like) {
          if (unlikeLength === props.config.max_dislike) {
            option.status = 0;
          } else {
            option.status = 2;
          }
        } else {
          if (option.status === 1 && unlikeLength === props.config.max_dislike) {
            option.status = 0;
          } else {
            option.status = option.status === undefined ? 1 : option.status += 1;
          }
        }
      }

      updateAnswer();
    } // 更新答案


    function updateAnswer() {
      var error = "";
      var answer = null;
      var likeLength = 0;
      var unlikeLength = 0;
      options.value.forEach(function (option) {
        if (option.status === 1) {
          likeLength += 1;
        } else if (option.status === 2) {
          unlikeLength += 1;
        }
      });

      if (likeLength || unlikeLength) {
        if (likeLength < props.config.min_like) {
          error = "\u8BF7\u9009\u62E9".concat(props.config.min_like, "\u4E2A\u559C\u6B22\u9009\u533A");
        } else if (unlikeLength < props.config.min_dislike) {
          error = "\u8BF7\u9009\u62E9".concat(props.config.min_dislike, "\u4E2A\u4E0D\u559C\u6B22\u9009\u533A");
        } else {
          answer = {};
          options.value.forEach(function (option) {
            answer[option.option_key] = option.status || 0;
          });
        }
      }

      context.emit("update:error", error);
      context.emit("update:answer", answer);
    } // 监听答案


    watch(function () {
      return props.answer;
    }, function () {
      context.emit("changeAnswer", {
        options: options.value
      });
    }, {
      deep: true,
      immediate: true
    }); // 监听list，更新关联选项

    watch(function () {
      return props.list;
    }, function () {
      var timer = setTimeout(function () {
        var newOptions = [];
        props.list.forEach(function (list) {
          newOptions = [].concat(_toConsumableArray(newOptions), _toConsumableArray(list.options));
        });
        newOptions.forEach(function (option) {
          var _props$answer2;

          if ((_props$answer2 = props.answer) !== null && _props$answer2 !== void 0 && _props$answer2[option.option_key]) {
            option.status = props.answer[option.option_key];
          }
        });
        options.value = newOptions; // 更新答案

        updateAnswer();
        clearTimeout(timer);
      });
    }, {
      deep: true
    });
    return {
      options: options,
      handleArea: handleArea
    };
  }
});