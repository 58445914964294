import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-035a2d30"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "error"
};
var _hoisted_2 = {
  class: "question-wrapper"
};
var _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Remark = _resolveComponent("Remark");

  var _component_matrix_radio = _resolveComponent("matrix-radio");

  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.error ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.error), 1)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, function (item) {
    return _openBlock(), _createElementBlock("div", {
      class: "kano",
      key: item
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
      class: "title answer-color",
      innerHTML: item.stem
    }, null, 8, _hoisted_3), !_ctx.isAnswer ? (_openBlock(), _createBlock(_component_Remark, {
      key: 0,
      title: _ctx.label + '问题评论',
      type: 3,
      questionIndex: _ctx.questionIndex
    }, null, 8, ["title", "questionIndex"])) : _createCommentVNode("", true)]), _createVNode(_component_matrix_radio, {
      list: item.list,
      config: _ctx.config,
      disabled: _ctx.disabled,
      answer: item.answer,
      "onUpdate:answer": [function ($event) {
        return item.answer = $event;
      }, _ctx.changeValue]
    }, null, 8, ["list", "config", "disabled", "answer", "onUpdate:answer"])]);
  }), 128))], 64);
}