import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-78bec5b4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "time"
};
var _hoisted_2 = {
  class: "consent"
};
var _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_button = _resolveComponent("a-button");

  var _component_a_modal = _resolveComponent("a-modal");

  return _openBlock(), _createBlock(_component_a_modal, {
    centered: "",
    width: 1000,
    visible: _ctx.show,
    maskClosable: false,
    closable: false,
    wrapClassName: "custom-modal",
    onCancel: _ctx.refuse
  }, {
    footer: _withCtx(function () {
      return [!_ctx.config.is_hide ? (_openBlock(), _createBlock(_component_a_button, {
        key: "back",
        loading: _ctx.loading,
        onClick: _ctx.refuse
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.config.disagree_button_text), 1)];
        }),
        _: 1
      }, 8, ["loading", "onClick"])) : _createCommentVNode("", true), _createVNode(_component_a_button, {
        key: "submit",
        type: "primary",
        loading: _ctx.loading,
        disabled: _ctx.time,
        onClick: _ctx.agree
      }, {
        default: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.config.agree_button_text), 1), _ctx.time ? (_openBlock(), _createElementBlock("span", _hoisted_1, "(" + _toDisplayString(_ctx.time) + "s)", 1)) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["loading", "disabled", "onClick"])];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        innerHTML: _ctx.title
      }, null, 8, _hoisted_3)])];
    }),
    _: 1
  }, 8, ["visible", "onCancel"]);
}