import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7492c10a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["q-heat", _ctx.isMobile ? 'q-heat-mob' : 'q-heat-pc'])
  }, [_createElementVNode("img", {
    src: _ctx.config.img_url,
    draggable: false
  }, null, 8, _hoisted_1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option) {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "area-group",
      key: option.option_key
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.option_config.child_area, function (area) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["area", "".concat(option.status ? 'active' : '', " ").concat(_ctx.config.show_type ? '' : 'border-dashed', " ").concat(_ctx.disabled ? 'disabled' : '')]),
        style: _normalizeStyle("top: ".concat(area.top_left[1], "%;left: ").concat(area.top_left[0], "%;width: ").concat(area.top_right[0] - area.top_left[0], "%;height: ").concat(area.bottom_left[1] - area.top_left[1], "%;")),
        onClick: function onClick($event) {
          return _ctx.handleArea(option);
        },
        key: area.child_index
      }, null, 14, _hoisted_2);
    }), 128))])), [[_vShow, !_ctx.hideOptions.includes(option.option_key)]]);
  }), 128))], 2);
}