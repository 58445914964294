import "core-js/modules/es.number.constructor.js";
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import * as cheerio from 'cheerio';
import openModal from "@/views/Answer/components/Modal/Comment";
import useEmitter from "@/composables/useEmitter"; // eslint-disable-next-line vue/no-unused-components

import { message } from "ant-design-vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: [Number, String],
      default: ''
    },
    questionIndex: {
      type: Number,
      default: undefined
    }
  },
  setup: function setup(props) {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var sn = computed(function () {
      return route.query.sn;
    });
    var visible = ref(false);
    var isLogin = ref(window.localStorage.getItem('plantToken'));
    var isPreview = computed(function () {
      return route.path === '/preview';
    });

    function openDrawer() {
      if (!isLogin.value) {
        message.success('您尚未登陆，请先登陆，再评论');
        router.push({
          name: 'login'
        });
        return;
      }

      var title = cheerio.load(props.title).text();
      store.commit("comment/ToggleCommentDrawer", true);
      openModal({
        sn: sn.value,
        title: title,
        type: props.type,
        questionIndex: props.questionIndex
      });
    }

    return {
      sn: sn,
      // visible,
      openDrawer: openDrawer,
      isLogin: isLogin,
      isPreview: isPreview
    };
  }
});